import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { useStreet } from './components/StreetSelector';

const processTempAndPHData = (data) => {
  const headers = ["Date", "Temperature (Celsius)"];
  const rows = data.map((entry) => [
    new Date(entry.DATE),
    entry["Temperature"]
  ]);
  return [headers, ...rows];
};

const processPhData = (data) => {
  const headers = ["Date", "pH"];
  const rows = data.map((entry) => [
    new Date(entry.DATE),
    entry.pH,
  ]);
  return [headers, ...rows];
};

const processChlorineData = (data) => {
  const headers = ["Date", "Total Chlorine Residual", "Monochloramine"];
  const rows = data.map((entry) => [
    new Date(entry.DATE),
    entry["Total Chlorine Residual"],
    entry.Monochloramine,
  ]);
  return [headers, ...rows];
};

const processFreeAmmoniaData = (data) => {
  const headers = ["Date", "Free Ammonia"];
  const rows = data.map((entry) => [
    new Date(entry.DATE),
    entry["Free Ammonia"],
  ]);
  return [headers, ...rows];
};

const processNitritesData = (data) => {
  const headers = ["Date", "Nitrites"];
  const rows = data.map((entry) => [
    new Date(entry.DATE),
    entry["Nitrites"],
  ]);
  return [headers, ...rows];
};

const processTotalAlkalinityAs_CaCO3 = (data) => {
  const headers = ["Date", "Total Alkalinity (as CaCO3)"];
  const rows = data.map((entry) => [
    new Date(entry.DATE),
    entry["Total Alkalinity (as CaCO3)"],
  ]);
  return [headers, ...rows];
};

const options = {
  title: 'Chlorine Quality Over Time',
  curveType: 'function',
  legend: { position: 'bottom' },
};

const LineChart = ({ data }) => {
  const { selectedStreet } = useStreet();

  const filteredData = data.filter((entry) => entry.Street === selectedStreet);

  const tempAndPHChartData = processTempAndPHData(filteredData);
  const chlorineChartData = processChlorineData(filteredData);
  const phChartData = processPhData(filteredData);
  const freeAmmoniaData = processFreeAmmoniaData(filteredData);
  const nitritesData = processNitritesData(filteredData);
  const totalAlkalinityAs_CaCO3Data = processTotalAlkalinityAs_CaCO3(filteredData);


  return (
    <div>
      {selectedStreet ? (
        <div>
          <Chart
            chartType="LineChart"
            width="100%"
            height="800px"
            data={chlorineChartData}
            options={options}
          />
        <Chart
          chartType="LineChart"
          width="100%"
          height="800px"
          data={phChartData}
          options={options}
        />
        <Chart
          chartType="LineChart"
          width="100%"
          height="800px"
          data={tempAndPHChartData}
          options={options}
        />
        <Chart
          chartType="LineChart"
          width="100%"
          height="800px"
          data={freeAmmoniaData}
          options={options}
        />
        <Chart
          chartType="LineChart"
          width="100%"
          height="800px"
          data={nitritesData}
          options={options}
        />
        <Chart
          chartType="LineChart"
          width="100%"
          height="800px"
          data={totalAlkalinityAs_CaCO3Data}
          options={options}
        />
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default LineChart;
