import React, { useState, useEffect } from 'react';
import './App.css';
import LineChart from './LineChart';
import BarChart from './BarChart';
import PieChart from './PieChart';
import NavigationBar from './NavigationBar';
import { StreetProvider, StreetSelector } from './components/StreetSelector';
import { useLoading } from './context/LoadingContext';
import { streetNames } from './data/streetData';
const streets = streetNames;

function App() {
  const { showLoading, hideLoading } = useLoading();
  const [data, setData] = useState([]);

  const getData = async () => {
    showLoading(); // Show global spinner
    try {
      const response = await fetch('waterqualitydata.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const myJson = await response.json();
      setData(myJson); // Set the data after parsing the JSON
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      hideLoading(); // Always hide the spinner, whether fetch succeeds or fails
    }
  }

  useEffect(() => {
    getData(); // Fetch data when the component mounts
  }, []);

  return (
    <StreetProvider>
      <div className="App">
        <NavigationBar />
        <h1>Water Quality Data</h1>
        <StreetSelector streetNames={streets} />
        <LineChart data={data} />
        <BarChart data={data} />
        <PieChart data={data} />
      </div>
    </StreetProvider>
  );
}

export default App;
